<template>
  <div class="vechile-alarm" ref="vechileAlarm">
    <el-tabs type="border-card" @tab-click="clickTab" v-model="form.type">
      <el-tab-pane label="统计" name="1">
        <div class="form-area" ref="form">
          <analysis-form
            @getFormVal="onSubmit"
            :formatDay="true"
            :showCar="false"
            :mistakeList="mistakeList"
            :mistakeShow="true"
            :platformAlarmShow="true"
          >
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(1)"
            >导出</el-button>
          </analysis-form>
        </div>
        <!-- 企业统计 -->
        <company-table ref="pagination1" :height="tableHeight - 40" :total="total" :tableData="comArr" />
      </el-tab-pane>
      <el-tab-pane label="详情" name="2">
        <div class="form-area">
          <analysis-form
            @getFormVal="onSubmit"
            :mistakeList="mistakeList"
            :mistakeShow="true"
            :platformAlarmShow="true"
          >
            <el-button
              type="primary"
              size="small"
              :disabled="carArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(2)"
            >导出</el-button>
          </analysis-form>
        </div>
        <!-- 车辆明细 -->
        <car-table
          ref="pagination2"
          :height="tableHeight"
          :total="total"
          :tableData="carArr"
          :modelObj="modelObj"
          :typeObj="typeObj"
          @sendInfo="getPageInfo"
        />
      </el-tab-pane>
      <el-tab-pane label="报警处理率统计" name="3">
        <div class="form-area" ref="form">
          <analysis-form :requireCompany="true" :showCar="false" :showMonth="true" @getFormVal="onSubmitOther">
          </analysis-form>
        </div>
        <!-- 报警处理率统计 -->
        <other-table
        ref="pagination3"
          :height="tableHeight + 40"
          :total="total"
          :tableData="otherArr"
          @sendInfo="getOtherPageInfo"
        ></other-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import companyTable from "./components/companyTable.vue";
import carTable from "./components/carTable.vue";
import otherTable from "./components/otherTable.vue";

import { queryAlarmVehicleAnalysis, queryCompanyAnalysis,queryAlarmDisposeAnalysis } from "@/api/lib/gps-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";

import { formatDict, getCurent, formatDate } from "@/common/utils/index";
export default {
  name: "vechileAlarm",
  components: {
    analysisForm,
    companyTable,
    carTable,
    otherTable
  },
  data () {
    return {
      tableHeight: 0,
      form: {
        type: "1",
        vehicleNos: [],
        companyId: [],
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
        mistake: 1,
        alarmSource: ''
      },
      otherForm:{
        currentPage: 1,
        pageSize: 10,
        companyId: [],
        beginTime: null,
        endTime: null,
      },
      carArr: [], //   车辆明细
      comArr: [],
      otherArr:[],
      total: 0,
      modelObj: null,
      typeObj: null,
      exportForm: {},
      downLoadStatus: false,
      mistakeList: [],
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.vechileAlarm.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 64;
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 10 - 40;
    },
    //导出
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:
          key === 1 ? `车辆报警统计-${getCurent()}` : `车辆报警详情-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:
          key === 1
            ? `/illegalExport/queryCompanyAnalysis`
            : `/illegalExport/queryVehicleAnalysisExport`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    onSubmit (value) {
      this.form.currentPage = 1;
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.mistake = value.mistake;
      this.form.alarmSource = value.alarmSource;
      this.getDataList();
    },
    onSubmitOther(value){
      this.otherForm.currentPage = 1;
      this.otherForm.companyId = value.companyId;
      this.otherForm.beginTime = value.beginTime;
      this.otherForm.endTime = value.endTime;
      this.getDataList();
    },
    // 获取列表
    getDataList () {
      let data = { ...this.form };
      let otherData = { ...this.otherForm };
      delete data.type;
      switch (this.form.type) {
        case "2":
          /**获取车辆详情*/
          queryAlarmVehicleAnalysis(data).then((res) => {
            if (res.code === 1000) {
              this.carArr = res.data.list;
              this.total = res.data.total;
            } else {
              this.carArr = [];
              this.total = 0;
            }
          });
          
          break;
        case "3":
          /**车辆报警率统计 */
          queryAlarmDisposeAnalysis(otherData).then(res=>{
            if (res.code === 1000) {
              this.otherArr = [res.data];
              this.total = 1;
            } else {
              this.otherArr = [];
              this.total = 0;
            }
          })
          break;
        case "1":
          /**获取企业统计*/
          delete data.vehicleNos;
          queryCompanyAnalysis(data).then((res) => {
            if (res.code === 1000 && res.data) {
              this.comArr = [res.data];
              this.total = 1;
            } else {
              this.comArr = [];
              this.total = 0;
            }
          });
          break;
      }
      this.exportForm = { ...data };
    },
    /**切换统计|详情 */
    clickTab () {
      this.total = 0;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.otherForm.currentPage = 1;
      this.otherForm.pageSize = 10;
      this.comArr = [];
      this.carArr = [];
      this.otherArr = [];
    },
    /**分页参数切换 */
    getPageInfo (params) {
      this.form.currentPage = params.currentPage;
      this.form.pageSize = params.pageSize;
      if (this.carArr.length === 0) return;
      this.getDataList();
    },
    getOtherPageInfo(params){
      this.otherForm.currentPage = params.currentPage;
      this.otherForm.pageSize = params.pageSize;
      if (this.otherArr.length === 0) return;
      this.getDataList();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CPYS,PCWB" }).then((res) => {
        if (res.code === 1000) {
          this.mistakeList = res.data.PCWB;
          this.modelObj = formatDict(res.data.VETY);
          this.typeObj = formatDict(res.data.CPYS);
        }
      });
    },
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.vechile-alarm {
  height: 100%;
  width: 100%;
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 40px);
    }
  }
}
.theme-project-gps {
  .vechile-alarm {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
