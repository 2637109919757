<template>
  <div class="multilevel">
    <el-table :data="tableData" stripe :height="height - 46" style="width: 100%">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column prop="companyName" width="160" :show-overflow-tooltip="true" label="所属企业"></el-table-column>
      <el-table-column prop="oneLevelAlarm" width="160" label="一级报警数量"></el-table-column>
      <el-table-column prop="oneLevelAlarmProcessed" width="160" label="一级报警处理数量"></el-table-column>
      <el-table-column prop="oneLevelAlarmProcessedPercent" width="160" label="一级报警处理率">
        <template slot-scope="scope">
          {{ scope.row.oneLevelAlarmProcessedPercent }}
          <span>{{ scope.row.oneLevelAlarmProcessedPercent?"%":"" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="twoLevelAlarm" width="160" label="二级报警数量"></el-table-column>
      <el-table-column prop="twoLevelAlarmProcessed" width="160" label="二级报警处理数量"></el-table-column>
      <el-table-column prop="twoLevelAlarmProcessedPercent" width="160" label="二级报警处理率">
        <template slot-scope="scope">
          {{ scope.row.twoLevelAlarmProcessedPercent }}
          <span>{{ scope.row.twoLevelAlarmProcessedPercent?"%":"" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alarmCount" label="报警总数"></el-table-column>
      <el-table-column prop="alarmProcessed" width="160" label="报警处理总数"></el-table-column>
      <el-table-column prop="alarmProcessedPercent" width="160" label="报警处理率">
        <template slot-scope="scope">
          {{ scope.row.alarmProcessedPercent }}
          <span>{{ scope.row.alarmProcessedPercent?"%":"" }}</span>
        </template></el-table-column>      
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    total: {
      type: Number
    },
    modelObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {},

  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },

  methods: {
    handleSizeChange(val) {
      this.pageObj.pageSize = val
      this.$emit('sendInfo', this.pageObj)
    },
    handleCurrentChange(val) {
      this.pageObj.currentPage = val
      this.$emit('sendInfo', this.pageObj)
    },
    reset() {
      this.pageObj.currentPage = 1
      this.pageObj.pageSize = 10
    }
  }
}
</script>

<style lang="scss" scoped>
.color-yellow{
  color:gold;
}
.color-red{
  color:#F74242;
}
</style>
