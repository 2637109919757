var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multilevel" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, stripe: "", height: _vm.height - 46 }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", width: "70", label: "序号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "companyName", label: "所属企业" }
          }),
          _c("el-table-column", { attrs: { prop: "cph", label: "车牌号" } }),
          _c("el-table-column", {
            attrs: { prop: "licensePlateColor", label: "车牌颜色" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.typeObj[Number(scope.row.licensePlateColor)]
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "车辆类型", "show-overflow-tooltip": true },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.modelObj[Number(scope.row.type)]) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmCount", label: "报警总数" }
          }),
          _c("el-table-column", {
            attrs: { prop: "oneLevelAlarm", label: "一级报警" }
          }),
          _c("el-table-column", {
            attrs: { prop: "twoLevelAlarm", label: "二级报警" }
          }),
          _c("el-table-column", {
            attrs: { prop: "positionRunMile", label: "里程数" }
          }),
          _c("el-table-column", {
            attrs: { prop: "avgRunMileAlarm", label: "百公里报警" }
          }),
          _c("el-table-column", {
            attrs: { prop: "score", label: "分数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        class:
                          scope.row.score < 80 ? "color-red" : "color-yellow"
                      },
                      [_vm._v(_vm._s(scope.row.score))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.pageObj.pageSize,
              "current-page": _vm.pageObj.currentPage
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }